import React from 'react'

export default function FlechaVolver({style}) {
  return (
    <svg viewBox="0 0 16 16"  fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={style}>
        <g id="SVGRepo_bgCarrier"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier"> 
            <path d="M5 1H4L0 5L4 9H5V6H11C12.6569 6 14 7.34315 14 9C14 10.6569 12.6569 12 11 12H4V14H11C13.7614 14 16 11.7614 16 9C16 6.23858 13.7614 4 11 4H5V1Z" fill="#currentColor"></path>
             </g></svg>
  )
}
