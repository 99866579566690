import React from 'react'

export default function GraficosIconos() {
    return (
        <>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className={`w-7 h-7 mb-1    group-hover:text-blue-700 `}
            ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier">
                    <g>
                        <rect width="30.364" height="457.98"></rect>
                        <rect
                            x="15.186"
                            y="332.475"
                            width="47.854"
                            height="20.062"></rect>
                        <rect
                            y="258.258"
                            width="65.356"
                            height="20.053"></rect>
                        <rect
                            x="15.186"
                            y="183.974"
                            width="47.854"
                            height="20.062"></rect>
                        <rect
                            y="109.684"
                            width="65.356"
                            height="20.135"></rect>
                        <rect
                            x="15.186"
                            y="35.472"
                            width="47.854"
                            height="20.062"></rect>
                        <path
                            d="M29.227,447.344L18.592,457.98L6.576,470.077l-4.222,4.222C0.893,472.513,0,470.321,0,467.885 v-20.541H29.227z"
                        ></path>
                        <path
                            d="M2.319,474.278c1.818,2.149,4.527,3.518,7.55,3.518H512v-30.435H29.236L2.319,474.278z"
                        ></path>
                        <path
                            d="M273.644,279.669v-20.464h-21.036l-33.627-32.714c-14.557,4.93-25.376,17.495-27.429,32.714h-0.4 v4.835c-0.001,0.123-0.03,0.239-0.03,0.363v141.84c0,7.953,6.667,14.44,14.842,14.44h52.861c8.175,0,14.842-6.487,14.842-14.44 V279.693L273.644,279.669z"
                        ></path>
                        <path
                            d="M273.668,264.404v6.486h-82.546v-6.486c0-17.527,11.668-32.429,27.859-37.912 c4.207-1.389,8.731-2.161,13.414-2.161C255.095,224.331,273.668,242.321,273.668,264.404z"
                        ></path>
                        <path
                            d="M128.338,137.603h-4.845c-21.353,0-38.823,17.47-38.823,38.823v158.857 c0,21.353,17.47,38.823,38.823,38.823h4.845c21.353,0,38.823-17.47,38.823-38.823V176.426 C167.161,155.073,149.691,137.603,128.338,137.603z"
                        ></path>
                        <path
                            d="M297.634,333.987c0,0,0-0.004,0-0.006V177.727c0-22.068,18.561-40.125,41.246-40.125 c22.686,0,41.246,18.057,41.246,40.125v6.449h-82.491V333.987z"
                        ></path>
                        <path
                            d="M297.634,184.176v149.812v35v37.293c0,7.919,6.66,14.398,14.801,14.398h38.334h0.001h14.555 c8.14,0,14.801-6.479,14.801-14.398v-72.299V184.176H297.634z"
                        ></path>
                        <path
                            d="M486.608,200.801l0-0.006V44.541c0-22.068-18.561-40.125-41.246-40.125 c-22.686,0-41.246,18.057-41.246,40.125v6.449h82.491V200.801z"
                        ></path>
                        <path
                            d="M273.668,264.404v6.486h-82.546v-6.486c0-17.527,11.668-32.429,27.859-37.912 c4.207-1.389,8.731-2.161,13.414-2.161C255.095,224.331,273.668,242.321,273.668,264.404z"
                        ></path>
                        <path
                            d="M128.338,137.603h-4.845c-21.353,0-38.823,17.47-38.823,38.823v158.857 c0,21.353,17.47,38.823,38.823,38.823h4.845c21.353,0,38.823-17.47,38.823-38.823V176.426 C167.161,155.073,149.691,137.603,128.338,137.603z"
                        ></path>
                        <path
                            d="M297.634,333.987c0,0,0-0.004,0-0.006V177.727c0-22.068,18.561-40.125,41.246-40.125 c22.686,0,41.246,18.057,41.246,40.125v6.449h-82.491V333.987z"
                        ></path>
                        <path
                            d="M486.608,200.801l0-0.006V44.541c0-22.068-18.561-40.125-41.246-40.125 c-22.686,0-41.246,18.057-41.246,40.125v6.449h82.491V200.801z"
                        ></path>
                        <path
                            d="M404.117,50.989v308.718c0,7.919,6.661,14.398,14.801,14.398h32.003h-0.001h-32.003 c-8.14,0-14.801-6.479-14.801-14.398v46.573c0,3.697,1.463,7.067,3.838,9.624c2.713,2.921,6.621,4.774,10.963,4.774h52.89 c8.141,0,14.801-6.479,14.801-14.398v-46.573V339.39v-288.4H404.117z"
                        ></path>
                        <path
                            d="M107.657,184.176H84.67V406.28c0,7.919,6.661,14.398,14.801,14.398h52.89 c8.141,0,14.801-6.479,14.801-14.398V242.063v-57.887H107.657z"
                        ></path>
                    </g>
                </g></svg
            >

            <span className="sr-only">Graficos</span>
        </>
    )
}
